<template>
  <div>
    <div class="flex items-center justify-between">
      <p class="text-2xl text-black">{{$t('Agent Training')}}</p>
      <vs-button @click="$router.push(`/${$route.params.lang}/ops-admin/agents/training-program`).catch(() => {})" color="primary" class="px-5 py-3">
        <p class="flex gap-2 items-center font-semibold text-white text-lg">
          <span>{{ 'New Training' }}</span>
          <span class="material-symbols-rounded text-xl font-medium">add</span>
        </p>
      </vs-button>
    </div>
    <div class="flex my-6 sm:justify-start justify-center">
      <div v-for="tab in tabs" :key="tab.index" @click="changeTabFilter(tab.value)" :class="`${$route.query.tab === tab.value ? 'active-tab' : 'text-darkgray border-0 border-b border-solid border-grey'} py-3 px-8 cursor-pointer`">
        <p class="font-medium text-lg leading-tight">{{$t(tab.name)}}</p>
      </div>
    </div>
    <div class="flex flex-col gap-8">
      <div v-for="(item, index) in trainingPrograms" :key="index" class="relative">
        <div class="absolute top-0 right-0 p-4">
          <button @click="updateProgramStatus(item)" class="material-icons-outlined text-grey-dark cursor-pointer outline-none border-0 bg-transparent">{{ item.status === 'inactive' ? 'unarchive' : 'archive' }}</button>
        </div>
        <div @click="goToProgramDetais(item)" class="border border-solid rounded border-grey-light md:p-8 p-4">
          <p class="font-medium text-xl text-black leading-tight">{{$t(`${item.name}`)}}</p>
          <div class="flex flex-wrap md:mt-16 mt-10 gap-x-24 gap-y-6 text-sm">
            <div>
              <p>{{ $t('Duration') }}</p>
              <p class="text-black">{{$t(`${item.duration}`)}}</p>
            </div>
            <div>
              <p>{{ $t('Agent Type') }}</p>
              <p v-for="(agent, index) in item.agent_type" :key="index" class="text-black capitalize">{{$t(`${agent.split('_').join(' ')}`)}}</p>
            </div>
            <div>
              <p>{{ $t('Existing Agents Due Date') }}</p>
              <p class="text-black">{{common.formatDate(item.start_date, $i18n.locale)}}</p>
            </div>
          </div>
        </div>
      </div>
      <p v-if="trainingPrograms.length === 0" class="text-center">{{'No Data Available'}}</p>
    </div>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>

<script>
import common from '../../assets/utils/common'
import { sendRequest } from '../../http/axios/requestHelper'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      common,
      tabs: [
        { 
          name: 'Active',
          value:'active'
        },
        {
          name: 'Archived',
          value: 'inactive'
        }
      ],
      activeTab: '',
      trainingPrograms: [],
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      totalRows: 0,
      offset: 0,
      currentPage: (this.$route.query.page && Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1
    }
  },
  watch: {
    '$route.params.lang' () {
      this.$router.push({
        query: {
          tab: this.activeTab,
          page: this.currentPage
        }
      }).catch(() => {})
    },
    '$route.query.tab' (val) {
      this.activeTab = val
      this.$router.push({
        query: {
          tab: val ? val : this.activeTab,
          page: 1
        }
      }).catch(() => {})
    },
    activeTab (val) {
      this.loadTrainingPrograms(val)
    },
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          tab: this.$route.query.tab,
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadTrainingPrograms(this.$route.query.tab)
    }
  },
  methods: {
    changeTabFilter (tab) {
      this.currentPage = 1
      this.activeTab = tab
      this.$router.push({
        query: {
          tab,
          page: this.currentPage
        }
      }).catch(() => {})
    },
    goToProgramDetais (program) {
      this.$router.push({
        name: 'training-program',
        query: {
          tab: 'details',
          programId: program.id
        }
      }).catch(() => {})
    },
    updateProgramStatus (program) {
      const data = {
        status: program.status === 'inactive' ? 'active' : 'inactive'
      }
      sendRequest(false, false, this, `api/v1/training/programs/${program.id}`, 'patch', data, true,
        () => {
          this.loadTrainingPrograms(this.$route.query.tab ? this.$route.query.tab : this.tabs[0].value)
        }
      )
    },
    loadTrainingPrograms (tab) {
      sendRequest(false, false, this, `api/v1/training/programs/?status=${tab}&limit=${this.maximumItems}&offset=${this.offset}`, 'get', null, true,
        (response) => {
          this.trainingPrograms = response.data.results
          this.totalRows = response.data.count
        }
      )
    }
  },
  components: {
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.changeTabFilter(this.$route.query.tab ? this.$route.query.tab : this.tabs[0].value)
  }
}
</script>

<style lang="scss">
.active-tab {
  background: #EDF4FB;
  color: #1C5BFE;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 2px solid #1C5BFE !important;
}
</style>